import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { Container, MarionBlogPost, Spacer } from '../uiBook';

export default function ProductPage({ data }) {
  const [pageData] = useState(data?.googleBlogsSheet);
  const dataObject = {
    node: { ...pageData },
  };
  const props = {
    data: dataObject,
  };
  return (
    <Layout disablePaddingBottom>
      <Container center padding="16px">
        <Spacer height={60} />
        <MarionBlogPost {...props} />
      </Container>
    </Layout>
  );
}
export const query = graphql`
  query ($slug: String!) {
    googleBlogsSheet(slug: { eq: $slug }) {
      images
      title
      author
      date
      copy
      captions
      slug
      download
    }
  }
`;
